<template>
    <td class="img_elem_wrpr image" :class="responsiveClasses">
        <img
            :src="imgSrc"
            :alt="basic.alternateText || advanced.alternateText"
            :id="advanced.cssId"
            :class="advanced.cssClasses"
            :style="[{'height': height, 'width': width}, style]"
            v-if="clickAction == 'No action'"
        >
        <a :href="basic.clickAction.url" v-else-if="clickAction !== 'No action'">
             <img
                :src="imgSrc"
                :alt="basic.alternateText || advanced.alternateText"
                :id="advanced.cssId"
                :class="advanced.cssClasses"
                :style="[{'height': height, 'width': width}, style]"
            >
        </a>
    </td>
</template>

<script>
export default {
    name: 'EmailImage',
    props: ['details'],
    computed: {
        imgSrc(){
            let localImgSrc = this.basic.image ? this.basic.image : '//images.funnelish.com/default/default-image.svg';

            if(localImgSrc.includes('funnel.webstaginghub.com/')) {
                return localImgSrc.replace('funnel.webstaginghub.com/', '');
            }
            if(localImgSrc.includes('email-editor/basic/')) {
                return localImgSrc.replace('email-editor/basic/', '');
            }

            return localImgSrc;
        },
        basic() {
            return this.details.attributes.basic;
        },
		    advanced() {
			    return this.details.attributes.advanced
		    },
		    responsive() {
			    return this.details.attributes.responsive
		    },
		    responsiveClasses() {
			    let c = '';
                if (!this.responsive) return '';
			    if (!this.responsive.desktop) c = 'no-desktop';
			    if (!this.responsive.mobile) c += ' no-mobile';
			    if (!this.responsive.tablet) c += ' no-tablet';
			    return c;
		    },
        height() {
            var currentHeight = this.basic.height ? this.basic.height : 'auto';
            if(currentHeight !== 'auto') {
                return currentHeight + 'px';
            } else {
                return currentHeight;
            }
        },
        width() {
            var currentWidth = this.basic.width ? this.basic.width : 'auto';
            if(currentWidth !== 'auto') {
                return currentWidth + 'px';
            } else {
                if(this.basic ? this.basic.margin : false){
                    return `calc(100% - ${(this.basic.margin.left || 0) + (this.basic.margin.right || 0)}px)`
                } else {
                    return 'auto'
                }
            }
        },
        style() {
            var border = this.basic.border;
            var borderTop = border && !border.sides.top ? 'none' : false;
            var borderLeft = border && !border.sides.left ? 'none' : false;
            var borderRight = border && !border.sides.right ? 'none' : false;
            var borderBottom = border && !border.sides.bottom ? 'none' : false;

            var borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;

	        var marginBase = this.basic.margin || {};
	        var paddingBase = this.basic.padding || {};

	        var margin =
			        parseInt(marginBase.top || 0)+'px '+
			        parseInt(marginBase.right || 0)+'px '+
			        parseInt(marginBase.bottom || 0)+'px '+
			        parseInt(marginBase.left || 0)+'px';

	        var padding =
			        parseInt(paddingBase.top || 0)+'px '+
			        parseInt(paddingBase.right || 0)+'px '+
			        parseInt(paddingBase.bottom || 0)+'px '+
			        parseInt(paddingBase.left || 0)+'px';

            var obj = {
                // width: '100%',
                margin,
                padding,
	              minWidth: '0',
                  maxWidth: '100%',
	              position: 'relative',
                borderWidth: border ? this.basic.border.width + 'px' : 'inherit',
                borderColor: border ? this.basic.border.color : '#ffffff',
                borderStyle: border ? this.basic.border.style : 'inherit',
                borderRadius,
                borderTop,
                borderLeft,
                borderRight,
                borderBottom,
            };
            return obj
        },
        clickAction() {
            return (this.basic.clickAction ? this.basic.clickAction.mainToggle : 'No action');
        }
    },
}
</script>
